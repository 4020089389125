<template>
    <div class="accounts-select">
        <SearchInput
            ref="dropdown"
            drop-down-width="600px"
            un-styled
            @on-selected="selected"
            :options="accountsDataComputed"
            :selected="{ value: preSelectedAccount }"
            :disabled="disabled"
            @enterKeyPressed="handleEnterKey"
        />
    </div>
</template>
<script>
import Enum from "@/common.enum.js";

export default {
    props: {
        companyType: {
            type: Number,
            default: null,
        },
        preSelectedAccount: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        SearchInput: () => import("../../../components/searchInput.vue"),
    },

    computed: {
        accountsDataComputed() {
            const accountPlan = this.companyType == Enum.companyType.Aktiebolag ? 1 : 2;
            const accountPlans = this.$store.state.accountPlans;
            const accountsData = accountPlans.filter(a => a.companyType == accountPlan)[0].accounts;
            return accountsData.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    interimAssetAccount: item.interimAssetAccount,
                    interimDebtAccount: item.numInterimDebtAccountber,
                };
            });
        },
    },

    data() {
        return {
            selectedAccount: this.preSelectedAccount || null,
        };
    },

    methods: {
        handleEnterKey() {
            this.$emit("enterKeyPressed");
        },

        selected(account) {
            this.$emit("selected", account);
        },

        selectBlur(status) {
            this.$emit("blur", status);
        },
    },
};
</script>
<style>
.accounts-select .el-select .el-input__inner {
    border: none;
}
</style>
