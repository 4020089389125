const Enum = {
    companyType: {
        None: 0,
        EnskildFirma: 1,
        Handelsbolag: 2,
        EkonomiskFörening: 3,
        Komanditbolag: 4,
        Aktiebolag: 5,
        AllmäntVissTid: 6,
        EndastFakturering: 7,
    },
};
export default Enum;